<template>
	<!-- 加班记录 -->
	<div class="mod-config">
		<el-card body-style="padding-bottom: 0;" style="margin-top: -5px;">
			<div class="title">考勤表</div>
			<div class="line"></div>
			<el-row style="margin-bottom: 5px;">
				<el-col :span="pageSpan" v-if="showAdd">
					<el-button v-preventReClick class="btn" type="primary" size="small" :disabled="updateFlg || addFlg"
						@click="addHandle()">添加人员</el-button>
					<el-button v-preventReClick class="btn" type="primary" size="small"
						:disabled="disabledBatchDelete || updateFlg || addFlg" @click="deleteBatch()">删除</el-button>
				</el-col>
				<el-col :span="pageSpan">
					<div style="margin-top: 8px;"> 考勤符号：出勤 √ 带津贴休假 ★ 驻地休息 ● 事假 □ 病假 △ 公休假 × 旷工 ○ 其他 ☆
					</div>
				</el-col>
			</el-row>
			<div class="line"></div>
			<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
				<el-row style="background-color: rgb(243, 247, 247);margin-top: -10px;margin-left: -20px;height: 45px;width: 103%;">
					<el-col :span="19" style="margin-left:20px">
						<el-form-item label="月份" class="selFormItem">
							<el-date-picker class="selDateInput" v-model="attendanceMonth" type="month" value-format="yyyy-MM" placeholder="选择月"
								:disabled="updateFlg || addFlg">
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :span="4" class="selBtnGroup" style="margin-top:5px">
						<el-button v-preventReClick class="btn" type="primary" size="small" @click="selGetDataList()"
							:disabled="updateFlg || addFlg" v-if="showSearch"> 查询 </el-button>
						<el-button v-preventReClick class="rebtn" size="small" :disabled="updateFlg || addFlg"
							@click="resetFormData()">重置</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-card>
		<div>
			<el-card style="margin-top: -25px; height: 545px;" body-style="height:51x0px; padding: 0;">
				<el-row style="height: 100%; margin-bottom: 10px;">
					<el-col :span="24" style="height: 100%;">
						<div class="box">
							<el-table height="490" :row-class-name="tableRowClassName" :row-style="selectRowBackground"
								class="dataListTable" :data="dataList" :key="tableKey" header-align="center" width="100%"
								header-cell-class-name="stepPointTHeader" border :header-cell-style="tableHeaderCellStyle"
								:cell-style="{ padding: '0px' }" style="margin-bottom: 20px;"
								@selection-change="handleSelectionChange">
								<el-table-column type="selection" width="60" align="center"></el-table-column>
								<el-table-column fixed="left" type="index" label="NO." width="60" align="center">
									<template slot-scope="scope">{{ (pageIndex2 - 1) * pageSize + scope.$index + 1
									}}</template>
								</el-table-column>
								<el-table-column fixed="left" prop="personName" label="姓名" align="center" width="100">
									<template slot-scope="scope">
										<span v-if="!getDisabled(scope.row.recId)">
											{{ scope.row.personName }}
										</span>
										<el-select class="sp-input" v-model="scope.row.personRecId" placeholder="请选择"
											v-if="getDisabled(scope.row.recId)">
											<el-option v-for="item in personList" :key="item.recId" :label="item.personName"
												:value="item.recId">
											</el-option>
										</el-select>
									</template>
								</el-table-column>
								<el-table-column :label="item.weekday" align="center" v-for="(item, subI) in days">
									<el-table-column :label="item.date" align="center">
										<template slot-scope="scope">
											<!-- <div class="setingSelBox" @click="setingSel(scope.$index, subI)" v-if="showSet">
									<i class="el-icon-check" v-if="scope.row.dayStatus[subI] == '1'"></i>
									<div class="notSelIcon" v-else></div>
								</div>
								<div class="setingSelBox" v-else>
									<i class="el-icon-check" v-if="scope.row.dayStatus[subI] == '1'"></i>
								</div> -->
											<span v-if="!getDisabled(scope.row.recId)">
												<span v-if="scope.row.dayStatus[subI] === '1'">√</span>
												<span v-else-if="scope.row.dayStatus[subI] === '2'">★</span>
												<span v-else-if="scope.row.dayStatus[subI] === '3'">●</span>
												<span v-else-if="scope.row.dayStatus[subI] === '4'">□</span>
												<span v-else-if="scope.row.dayStatus[subI] === '5'">△</span>
												<span v-else-if="scope.row.dayStatus[subI] === '6'">×</span>
												<span v-else-if="scope.row.dayStatus[subI] === '7'">○</span>
												<span v-else-if="scope.row.dayStatus[subI] === '8'">☆</span>
											</span>
											<el-select class="sp-input" v-model="scope.row.dayStatus[subI]" placeholder=""
												v-if="getDisabled(scope.row.recId)" @change="changeSelect(scope.$index)"
												clearable>
												<el-option v-for="item in statusList" :key="item.id" :label="item.label"
													:value="item.id">
												</el-option>
											</el-select>
										</template>
									</el-table-column>
								</el-table-column>
								<el-table-column label="驻地出勤" header-align="center">
									<el-table-column prop="attDays" label="天" header-align="center" align="center"
										width="60px"></el-table-column>
								</el-table-column>
								<el-table-column label="驻地休息" header-align="center">
									<el-table-column prop="restDays" label="天" header-align="center" align="center"
										width="60px"></el-table-column>
								</el-table-column>
								<el-table-column label="驻地 休息" header-align="center">
									<el-table-column prop="restPayDays" label="带津贴" header-align="center" align="center"
										width="67px"></el-table-column>
								</el-table-column>
								<el-table-column label="非驻地统计" header-align="center">
									<el-table-column prop="leaveAbsenceDays" label="事" header-align="center" align="center"
										width="51px"></el-table-column>
									<el-table-column prop="sickDays" label="病" header-align="center" align="center"
										width="51px"></el-table-column>
									<el-table-column prop="sabbaticalDays" label="公" header-align="center" align="center"
										width="51px"></el-table-column>
									<el-table-column prop="minerDays" label="旷" header-align="center" align="center"
										width="51px"></el-table-column>
									<el-table-column prop="otherDays" label="其他" header-align="center" align="center"
										width="52px"></el-table-column>
								</el-table-column>
								<el-table-column label="" header-align="center" width="90px" fixed="right">
									<template slot-scope="scope">
										<el-button v-preventReClick type="text" size="mini"
											v-if="!getDisabled(scope.row.recId) && !addFlg && !updateFlg"
											style="margin-left: 20px;" @click="updateHandle(scope.row.recId)">修改</el-button>
										<!--新增时按钮-->
										<el-button v-preventReClick type="text" size="mini"
											v-if="getDisabled(scope.row.recId) && addFlg"
											@click="saveAttendance()">保存</el-button>
										<el-button v-preventReClick type="text" size="mini"
											v-if="getDisabled(scope.row.recId) && addFlg"
											@click="cancelAddHandle()">取消</el-button>
										<!--修改时按钮-->
										<el-button v-preventReClick type="text" size="mini"
											v-if="getDisabled(scope.row.recId) && updateFlg"
											@click="updateAttendance(scope.row)">保存</el-button>
										<el-button v-preventReClick type="text" size="mini"
											v-if="getDisabled(scope.row.recId) && updateFlg"
											@click="cancelUpdateHandle()">取消</el-button>
									</template>
								</el-table-column>
							</el-table>
						</div>
						<el-pagination class="my-pagination" @size-change="sizeChangeHandle" :disabled="updateFlg || addFlg"
							@current-change="currentChangeHandle" :current-page="pageIndex" :page-sizes="[10, 20, 50, 100]"
							:page-size="pageSize" :total="totalPage" layout="total, sizes, prev, pager, next, jumper">
						</el-pagination>
					</el-col>
				</el-row>
			</el-card>
		</div>
		<add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList"></add-or-update>
	</div>
</template>

<script>
import AddOrUpdate from './attendance-add-or-update'
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			disabledBatchDelete: false,
			showSearch: false,
			showAdd: false,
			showSet: false,
			pageSpan: 12,
			form: {},
			addOrUpdateVisible: false,
			dayList: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
			attendanceMonth: "",
			dataList: [],
			tableKey: 0,
			//当前页码
			pageIndex: 1,
			// 显示序号
			pageIndex2: 1,
			//当前记录数
			pageSize: 20,
			//总页数
			totalPage: 0,
			tableSelVal: [],
			statusList: [
				// { label: " ", id: "0" },
				{ label: "√", id: "1" },
				{ label: "★", id: "2" },
				{ label: "●", id: "3" },
				{ label: "□", id: "4" },
				{ label: "△", id: "5" },
				{ label: "×", id: "6" },
				{ label: "○", id: "7" },
				{ label: "☆", id: "8" },
			],
			selectRecId: '',
			updateFlg: false,
			addFlg: false,
			personList: [],
			days: [],
			rows: []
		};
	},
	components: {
		AddOrUpdate,
	},
	mounted() {
		this.showSet = $common.isAuth('attendance:set');
		this.showSearch = $common.isAuth('attendance:search');
		this.showAdd = $common.isAuth('attendance:add');
		if (!this.showAdd) {
			this.pageSpan = 24;
		}
		this.getDataList();
	},
	methods: {
		getDisabled(val) {
			if (val == '' || val == undefined) {
				return true;
			} else {
				if (this.selectRecId === val && this.updateFlg) {
					return true;
				}
				return false;
			}
		},
		//自适应高度
		siteContentViewHeight() {
			var height = this.$store.state.common.documentClientHeight - 315;
			return {
				height: height + 'px'
			}
		},
		getRowClass(row, rowIndex) {
			if (!row.row.subList || row.row.subList.length === 0) {
				return 'row-expand-cover'
			}
			return ''
		},
		currentChangeHandle(val) {
			this.pageIndex = val
			this.getDataList()
		},
		// 每页数
		sizeChangeHandle(val) {
			this.pageSize = val
			this.pageIndex = 1
			this.getDataList()
		},
		addOrUpdateHandle() {
			this.addOrUpdateVisible = true
			this.$nextTick(() => {
				this.$refs.addOrUpdate.init()
			})
		},
		tableHeaderCellStyle({ row, column, rowIndex, columnIndex }) {
			if (this.rows != undefined && this.rows.length > 0) {
				for (var j = 0; j < this.rows.length; j++) {
					if (columnIndex === this.rows[j] && rowIndex == 0) {
						return 'background:rgb(211, 243, 201) !important'
					} else if (columnIndex + 3 === this.rows[j] && rowIndex == 1) {
						return 'background:rgb(211, 243, 201) !important'
					}
				}
			}
		},
		handleSelectionChange(selection) {
			this.tableSelVal = selection;
			if (selection.length > 0) {
				this.disabledBatchDelete = false;
			} else {
				this.disabledBatchDelete = true;
			}
		},
		addHandle() {
			if (this.addFlg) {
				return;
			}
			this.$http({
				url: this.$store.state.httpUrl + "/business/attendanceinfo/getAttendancePersonList",
				method: "POST",
				data: {
					attendanceMonth: this.attendanceMonth,
					subjectNo: $common.getItem("subjectNo"),
					workNo: $common.getItem("workNo"),
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.personList = data.body.records;
					if (this.personList.length == 0) {
						this.$message.error("所有人员已添加至考勤记录中，没有待选人员")
						return;
					} else {
						this.addFlg = true;

						const attendanceMonth = new Date(this.attendanceMonth)
						const daysInMonth = new Date(attendanceMonth.getFullYear(), attendanceMonth.getMonth() + 1, 0).getDate();

						const dayStatus = [];
						if(daysInMonth > 0) {
							for (var i = 0; i < daysInMonth; i++) {
								dayStatus.push("1");
							}
							for (var i = 0; i < 31 - daysInMonth; i++) {
								dayStatus.push("0");
							}
						}
						let attendance = {
							workNo: $common.getItem("workNo"),
							subjectNo: $common.getItem("subjectNo"),
							attendanceMonth: this.attendanceMonth,
							recId: '',
							personRecId: '',
							// dayStatus: ["1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", "1", " ",],
							dayStatus: dayStatus,
							attDays: daysInMonth,
							restDays: 0,
							restPayDays: 0,
							leaveAbsenceDays: 0,
							sickDays: 0,
							sabbaticalDays: 0,
							minerDays: 0,
							otherDays: 0,
						}
						let dataList = [];
						dataList.push(attendance);
						if (this.dataList != undefined && this.dataList.length > 0) {
							for (var i = 0; i < this.dataList.length; i++) {
								dataList.push(this.dataList[i])
							}
						}
						this.dataList = dataList;
					}
				}
			});
		},
		cancelAddHandle() {
			this.addFlg = false;
			this.getDataList()
		},
		updateHandle(val) {
			this.selectRecId = val;
			this.updateFlg = true;
			/**重新请求人员信息 */
			this.getAttendancePersonList()
		},
		cancelUpdateHandle() {
			this.selectRecId = '';
			this.updateFlg = false;
			this.getDataList()
		},
		changeSelect(index) {
			var data = this.dataList[index];
			// 计算天数
			var dayStatus = data.dayStatus;
			let attDays = 0;
			let restDays = 0;
			let restPayDays = 0;
			let leaveAbsenceDays = 0;
			let sickDays = 0;
			let sabbaticalDays = 0;
			let minerDays = 0;
			let otherDays = 0;
			for (var j = 0; j < dayStatus.length; j++) {
				if (dayStatus[j] === "1") {
					attDays++;
				} else if (dayStatus[j] === "2") {
					restPayDays++;
				} else if (dayStatus[j] === "3") {
					restDays++;
				} else if (dayStatus[j] === "4") {
					leaveAbsenceDays++;
				} else if (dayStatus[j] === "5") {
					sickDays++;
				} else if (dayStatus[j] === "6") {
					sabbaticalDays++;
				} else if (dayStatus[j] === "7") {
					minerDays++;
				} else if (dayStatus[j] === "8") {
					otherDays++;
				}
			}
			this.dataList[index].attDays = attDays;
			this.dataList[index].restPayDays = restPayDays;
			this.dataList[index].restDays = restDays;
			this.dataList[index].leaveAbsenceDays = leaveAbsenceDays;
			this.dataList[index].sickDays = sickDays;
			this.dataList[index].sabbaticalDays = sabbaticalDays;
			this.dataList[index].minerDays = minerDays;
			this.dataList[index].otherDays = otherDays;
		},
		getDataList() {
			if (this.showSearch) {
				this.$http({
					url: this.$store.state.httpUrl + "/business/attendanceinfo/list",
					method: "get",
					params: {
						workNo: $common.getItem("workNo"),
						subjectNo: $common.getItem("subjectNo"),
						attendanceMonth: this.attendanceMonth,
						limit: this.pageSize,
						page: this.pageIndex
					},
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.dataList = data.body.records;
						this.attendanceMonth = data.body.attendanceMonth;
						this.totalPage = data.body.totalCount;
						this.pageIndex2 = this.pageIndex

						this.days = this.monthdays(new Date(this.attendanceMonth))

						if (this.dataList != undefined && this.dataList.length > 0) {
							for (var i = 0; i < this.dataList.length; i++) {
								var status = []
								for (var j = 0; j < this.dataList[i].dayStatus.length; j++) {
									if (this.dataList[i].dayStatus[j] === "0") {
										status.push(" ")
									} else {
										status.push(this.dataList[i].dayStatus[j])
									}
								}
								this.dataList[i].dayStatus = status;
							}
						}
					}
				});
			}
		},
		monthdays(attendanceMonth) {
			const daysInMonth = new Date(attendanceMonth.getFullYear(), attendanceMonth.getMonth() + 1, 0).getDate();
			const days = [];
			const rows = [];
			for (let i = 1; i <= daysInMonth; i++) {
				const date = new Date(`${attendanceMonth.getFullYear()}-${attendanceMonth.getMonth() + 1}-${i}`);
				var dateDay;
				if (i < 10) {
					dateDay = '0' + i;
				} else {
					dateDay = i.toString();
				}
				var day;
				if (date.getDay() == '0') {
					day = '日'
					rows.push(i + 2)
				} else if (date.getDay() == '1') {
					day = '一'
				} else if (date.getDay() == '2') {
					day = '二'
				} else if (date.getDay() == '3') {
					day = '三'
				} else if (date.getDay() == '4') {
					day = '四'
				} else if (date.getDay() == '5') {
					day = '五'
				} else if (date.getDay() == '6') {
					day = '六'
					rows.push(i + 2)
				}
				days.push({ date: dateDay, weekday: day });
				this.rows = rows
			}
			return days;
		},
		setingSel(index, subI) {
			let _dataList = this.dataList;
			let _dayStatusList = _dataList[index].dayStatus;
			_dayStatusList[subI] = _dayStatusList[subI] === "0" ? "1" : "0";
			_dataList[index].dayStatus = _dayStatusList;
			this.$http({
				url: this.$store.state.httpUrl + "/business/attendanceinfo/setAttendance",
				method: "POST",
				data: {
					dayStatus: _dayStatusList[subI],
					days: subI,
					recId: _dataList[index].recId,
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					this.dataList = _dataList;
					this.tableKey = Math.random();
				}
			});
		},
		resetFormData() {
			this.attendanceMonth = "";
		},
		selGetDataList() {
			this.pageIndex = 1;
			this.getDataList();
		},
		getAttendancePersonList() {
			this.$http({
				url: this.$store.state.httpUrl + "/business/attendanceinfo/getAttendancePersonList",
				method: "POST",
				data: {
					attendanceMonth: this.attendanceMonth,
					subjectNo: $common.getItem("subjectNo"),
					workNo: $common.getItem("workNo"),
					recId: this.selectRecId
				},
			}).then(({ data }) => {
				if (data && data.resultCode === 200) {
					// this.dataForm.attendanceMonth = data.body.attendanceMonth;
					this.personList = data.body.records;
				}
			});
		},
		saveAttendance() {
			if (this.dataList[0].personRecId == null || this.dataList[0].personRecId == "") {
				this.$message.error("请选择姓名");
				return;
			}
			let _url = "/business/attendanceinfo/saveAttendance";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(this.dataList[0])
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
					});
					this.getDataList()
					this.addFlg = false
				}
			});
		},
		updateAttendance(val) {
			if (val.personRecId == null || val.personRecId == "") {
				this.$message.error("请选择姓名");
				return;
			}
			let _url = "/business/attendanceinfo/updateAttendance";
			this.$http({
				url: this.$store.state.httpUrl + _url,
				method: "post",
				data: this.$http.adornData(val)
			}).then(({
				data
			}) => {
				if (data && data.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
					});
					this.getDataList()
					this.updateFlg = false
					this.selectRecId = '';
				}
			});
		},
		deleteBatch() {
			this.$confirm(`确定对选中的数据进行删除操作？`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let records = this.tableSelVal.map((item) => item.recId);
				this.$http({
					url: this.$store.state.httpUrl + "/business/attendanceinfo/deleteBatch",
					method: 'post',
					data: this.$http.adornData({
						records: records
					})
				}).then(({ data }) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: '操作成功',
							type: 'success',
							duration: 1500,
							onClose: () => {
								this.getDataList()
							}
						})
					}
				})
			})
				.catch(() => { })
		},
		tableRowClassName({ row, rowIndex }) {
			return $common.tableRowClassName({ row, rowIndex });
		},
		selectRowBackground({ row }) {
			const checkIdList = this.tableSelVal.map((item) => item.recId);
			if (checkIdList.includes(row.recId)) {
				return {
					backgroundColor: "#fae8cb",
				};
			}
		},
	}
};
</script>
<style scoped>
.sp-input /deep/ .el-input__inner {
	width: 100% !important;
}
</style>
</script>

<style lang="scss" scoped>
.notSelIcon {
	width: 14px;
	height: 14px;
	margin: 0 auto;
	border: 1px solid #999999;
}

.my-pagination {
	margin-right: 20px !important;
}

// 设置表头样式
::v-deep(.el-table th.el-table__cell) {
	font-size: 15px;
	padding: 2px 0;
}

.selDateInput {
	width: 150px !important;
	margin-top: 2px;
	height: 32px;

	::v-deep .el-input__inner {
		height: 33px;
	}
}

::v-deep(.selFormItem .el-form-item__label) {
	font-size: smaller;
}

::v-deep(.el-table__fixed-body-wrapper) {
  top:86px !important;
}

</style>
